import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';
import { objectIsEmpty } from 'utils/functions';
// import axios from 'axios';
import { axiosParcels, axiosParcelsAPI, axiosPost } from 'hooks/axiosInstance';

// const airtableKey = process.env.REACT_APP_PARCELS_AIRTABLE_KEY;

const mapPackages = (values) =>
    values.packages.map((obj) => ({
        units: 'METRIC',
        value: obj.value,
        weight: obj.weight,
        length: obj.depth,
        width: obj.width,
        height: obj.height,
        description: obj.description,
        line_items: !objectIsEmpty(obj.line_items[0]) ? obj.line_items : []
    }));

const initialState = {
    parcels: [],
    orgData: {},
    addresses: [],
    parcelRates: [],
    orgServices: [],
    apiToken: '',
    apiParcels: [],
    carrierCredentials: {},
    apiLables: [],
    apiLablesResponse: {},
    carrierIds: []
};

const slice = createSlice({
    name: 'parcels',
    initialState,
    reducers: {
        getParcelsSuccess(state, action) {
            state.parcels = action.payload;
        },
        getOrgDataSuccess(state, action) {
            state.orgData = action.payload;
        },
        getOrgAddressesSuccess(state, action) {
            state.addresses = action.payload;
        },
        getParcelRatesSuccess(state, action) {
            state.parcelRates = action.payload;
        },
        getOrgServiceTypesSuccess(state, action) {
            state.orgServices = action.payload;
        },
        getPatilloTokenSuccess(state, action) {
            state.apiToken = action.payload;
        },
        getPatilloParcelSuccess(state, action) {
            state.apiParcels = action.payload;
        },
        getPatilloLabelsSuccess(state, action) {
            state.apiLables = action.payload;
        },
        getCarrierCredentialsSuccess(state, action) {
            state.carrierCredentials = action.payload;
        },
        getCarrierIdsSuccess(state, action) {
            state.carrierIds = action.payload;
        },
        setApiLabelsResponse(state, action) {
            state.apiLablesResponse = action.payload;
        }
    }
});

export default slice.reducer;

export function getParcels(orgId) {
    return async () => {
        try {
            const response = await axiosParcels.get(
                `Parcels?fields%5B%5D=InternalId&fields%5B%5D=ExternalId&fields%5B%5D=CreatedAt&fields%5B%5D=Origin&fields%5B%5D=OriginContactFirstName&fields%5B%5D=OriginContactLastName&fields%5B%5D=OriginContactPhone&fields%5B%5D=OriginContactEmail&fields%5B%5D=OriginAddressAddress1&fields%5B%5D=OriginAddressAddress2&fields%5B%5D=OriginAddressZipCode&fields%5B%5D=OriginAddressState&fields%5B%5D=OriginAddressCity&fields%5B%5D=OriginAddressCountry&fields%5B%5D=OriginAddressReferences&fields%5B%5D=Destination&fields%5B%5D=DestinationContactFirstName&fields%5B%5D=DestinationContactLastName&fields%5B%5D=DestinationContactPhone&fields%5B%5D=DestinationContactEmail&fields%5B%5D=DestinationAddressAddress1&fields%5B%5D=DestinationAddressAddress2&fields%5B%5D=DestinationAddressZipCode&fields%5B%5D=DestinationAddressCity&fields%5B%5D=DestinationAddressState&fields%5B%5D=DestinationAddressCountry&fields%5B%5D=DestinationAddressReferences&fields%5B%5D=ServiceId&fields%5B%5D=ServiceType&fields%5B%5D=Carrier&fields%5B%5D=TrackingNumber&fields%5B%5D=TrackingUrl&fields%5B%5D=ParcelLabel&fields%5B%5D=Height&fields%5B%5D=Width&fields%5B%5D=Depth&fields%5B%5D=Weight&fields%5B%5D=SubtotalPrice&fields%5B%5D=TotalPrice&fields%5B%5D=SubtotalCost&fields%5B%5D=TotalCost&filterByFormula=%7BOrganizationId%7D%3D%22${orgId}%22`
            );
            dispatch(slice.actions.getParcelsSuccess(response.data.records));
        } catch (error) {
            console.log(error);
        }
    };
}

export function getOrgBalance(orgId) {
    return async () => {
        try {
            const response = await axiosParcels.get(
                `OrganizationBalance?fields%5B%5D=Parcels&fields%5B%5D=NameOrg&fields%5B%5D=Balance&fields%5B%5D=CreditLine&fields%5B%5D=Status&fields%5B%5D=Addresses&fields%5B%5D=ServiceId&fields%5B%5D=Carrier&fields%5B%5D=ServiceType&fields%5B%5D=ProfitMultiplier&filterByFormula=%7BOrgId%7D%3D%22${orgId}%22`
            );
            // console.log('org balance', response.data.records[0]);
            dispatch(slice.actions.getOrgDataSuccess(response.data.records[0]));
        } catch (error) {
            console.log(error);
        }
    };
}

export function getOrgAddresses(orgId) {
    return async () => {
        try {
            const data = [];
            let offset = '';
            while (offset !== null) {
                // eslint-disable-next-line no-await-in-loop
                await axiosParcels
                    .get(`Addresses?view=Addresses&filterByFormula='{OrganizationId}=${orgId}'&pageSize=10&offset=${offset}`)
                    // eslint-disable-next-line no-loop-func
                    .then((res) => {
                        data.push(...res.data.records);

                        if (res.data.offset) {
                            offset = res.data.offset;
                        } else {
                            offset = null;
                        }
                    });
            }

            console.log(data);
            dispatch(slice.actions.getOrgAddressesSuccess(data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function getOrgServiceTypes(orgId) {
    return async () => {
        try {
            const response = await axiosParcels.get(
                `Services?fields%5B%5D=Id&fields%5B%5D=Nacional&fields%5B%5D=Carrier&fields%5B%5D=ServiceType&fields%5B%5D=CarrierId&fields%5B%5D=ProfitMultiplier&filterByFormula=OR(FIND('${orgId}', Organizations))`
            );
            // console.log('org service types', response.data.records);
            dispatch(slice.actions.getOrgServiceTypesSuccess(response.data.records));
        } catch (error) {
            console.log(error);
        }
    };
}

export function postAddress(values, orgId, callback) {
    const fields = {
        fields: {
            Type: values.type,
            OrganizationId: orgId,
            AddressName: values.contact.addressName,
            ContactFirstName: values.contact.firstName,
            ContactLastName: values.contact.lastName,
            ContactPhone: values.contact.phoneNumber,
            ContactEmail: values.contact.email,
            AddressAddress1: values.address.address1,
            AddressAddress2: values.address.address2,
            AddressZipCode: values.address.zipCode,
            AddressState: values.address.state,
            AddressCity: values.address.city,
            AddressCountry: values.address.country,
            AddressReferences: values.address.references,
            Company: values.contact.company
        },
        typecast: true
    };
    return async () => {
        try {
            const response = await axiosPost.post('Addresses', fields);
            callback(response);
        } catch (error) {
            console.log(error);
        }
    };
}

export function postParcel(values, callback) {
    // console.log('arranged parcel values', values);
    return async () => {
        try {
            const response = await axiosPost.post('Parcels', { records: values, typecast: true });
            callback(response);
        } catch (error) {
            console.log(error);
            callback(error);
        }
    };
}

export function getApiParcels(token) {
    return async () => {
        try {
            const response = await axiosParcelsAPI.instance.get('/v1/shipments/get-all', {
                headers: {
                    'api-header': token
                }
            });
            dispatch(slice.actions.getPatilloParcelSuccess(response.data.labels));
        } catch (error) {
            console.log(error);
        }
    };
}

export function resetApiLabels() {
    return async () => {
        try {
            dispatch(slice.actions.getPatilloLabelsSuccess([]));
            dispatch(slice.actions.setApiLabelsResponse({}));
        } catch (error) {
            console.log(error);
        }
    };
}

export function getApiLables(token, withTracking, pageSize, page, sortBy, sortOrder) {
    const params = new URLSearchParams([
        ['with_tracking', withTracking],
        ['page_size', pageSize],
        ['page', page],
        ['sort_by', sortBy],
        ['sort_order', sortOrder]
    ]);
    return async () => {
        try {
            const response = await axiosParcelsAPI.instance.get('/v1/labels/get-all', {
                params,
                headers: {
                    'api-header': token
                }
            });
            // console.log('apilables response', response);
            dispatch(slice.actions.getPatilloLabelsSuccess(response.data.labels));
            dispatch(slice.actions.setApiLabelsResponse(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function getRates(token, values, callback) {
    const fields = {
        origin: {
            city: values.origin.city,
            state: values.origin.state,
            zipCode: values.origin.zipCode,
            countryCode: values.origin.AddressCountry
        },
        destination: {
            city: values.destination.city,
            state: values.destination.state,
            zipCode: values.destination.zipCode,
            countryCode: values.destination.AddressCountry
        },
        packages: mapPackages(values),
        is_customs_declarable: false,
        is_insured: values.is_insured
    };
    return async () => {
        try {
            const response = await axiosParcelsAPI.instance.post('/v1/shipments/get-rates', fields, {
                headers: {
                    'api-header': token
                }
            });
            callback(response);
            dispatch(slice.actions.getParcelRatesSuccess(response.data.rates_generated));
        } catch (error) {
            console.log(error);
            callback(error);
        }
    };
}

export function generateLabel(token, values, callback) {
    const today = new Date();
    const fields = {
        origin: {
            contact: {
                name: `${values.origin.ContactFirstName} ${values.origin.ContactLastName}`,
                email: values.origin.ContactEmail,
                phoneNumber: values.origin.ContactPhone,
                phoneExtension: ''
            },
            address: {
                street1: values.origin.AddressAddress1,
                street2: values.origin.AddressAddress2 ? values.origin.AddressAddress2 : '.',
                city: values.origin.AddressCity,
                state: values.origin.AddressState,
                zipCode: values.origin.AddressZipCode,
                countryCode: values.origin.AddressCountry,
                references: values.origin.AddressReferences
            }
        },
        destination: {
            contact: {
                name: `${values.destination.ContactFirstName} ${values.destination.ContactLastName}`,
                email: values.destination.ContactEmail,
                phoneNumber: values.destination.ContactPhone,
                phoneExtension: ''
            },
            address: {
                street1: values.destination.AddressAddress1,
                street2: values.destination.AddressAddress2 ? values.destination.AddressAddress2 : '.',
                city: values.destination.AddressCity,
                state: values.destination.AddressState,
                zipCode: values.destination.AddressZipCode,
                countryCode: values.destination.AddressCountry,
                references: values.destination.AddressReferences
            }
        },
        packages: mapPackages(values),
        service_type: values.packageInfo.serviceType,
        carrier: values.packageInfo.carrier.toUpperCase(),
        is_customs_declarable: values.is_customs_declarable,
        is_insured: values.is_insured,
        description: values.description,
        invoice: {
            file_id: values.invoice?.id || null,
            number: values.invoice?.number || '',
            date: today.toISOString().slice(0, 10)
        }
    };

    return async () => {
        try {
            const response = await axiosParcelsAPI.instance.post('/v1/shipments/generate', fields, {
                headers: {
                    'api-header': token
                }
            });
            callback(response);
        } catch (error) {
            console.log(error);
            callback(error);
        }
    };
}

export function generateBatchLabels(token, formData, callback) {
    return async () => {
        try {
            const response = await axiosParcelsAPI.instance.post('/v1/shipments/generate-batch', formData, {
                headers: {
                    'api-header': token,
                    'Content-Type': 'multipart/form-data'
                }
            });
            callback(response);
        } catch (error) {
            console.log(error);
            callback(error);
        }
    };
}

export function clearParcelRates() {
    return () => {
        dispatch(slice.actions.getParcelRatesSuccess([]));
    };
}

export function postInvoice(token, file, callback) {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('file_type', 'invoice');
    return async () => {
        try {
            const response = await axiosParcelsAPI.instance.post('/v1/files/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'api-header': token
                }
            });
            callback(response);
        } catch (error) {
            console.log(error);
        }
    };
}

export function deleteInvoice(id, callback) {
    return async () => {
        try {
            const response = await axiosParcelsAPI.instance.delete(`/v1/files/${id}`);
            callback(response);
        } catch (error) {
            console.log(error);
        }
    };
}

export function generateToken() {
    return async () => {
        try {
            const response = await axiosParcelsAPI.instance.post('/v1/api-key/generate-token?expiration_days=30');
            dispatch(slice.actions.getPatilloTokenSuccess(response.data.token));
            // console.log('slice.apiToken', slice.actions.get);
        } catch (error) {
            console.log(error);
        }
    };
}

export function getCredentials(token) {
    return async () => {
        try {
            const response = await axiosParcelsAPI.instance.get('/v1/organizations/get-credentials', {
                headers: {
                    'api-header': token
                }
            });
            dispatch(slice.actions.getCarrierCredentialsSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function addCredentials(values, token, callback, companyId) {
    const actualValues = { ...values, password: null, username: null, shippingCompanyId: companyId };
    return async () => {
        try {
            const response = await axiosParcelsAPI.instance.put('v1/organizations/add-own-credential', [actualValues], {
                headers: {
                    'api-header': token
                }
            });
            callback(response);
        } catch (error) {
            console.log('credentials error', error);
        }
    };
}

export function getCarrierIds() {
    return async () => {
        try {
            const response = await axiosParcelsAPI.instance.get('v1/shipping-companies/get-all');
            dispatch(slice.actions.getCarrierIdsSuccess(response.data));
        } catch (error) {
            console.log('credentials error', error);
        }
    };
}
