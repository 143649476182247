import { eachMonthOfInterval, format } from 'date-fns';

/* eslint-disable import/prefer-default-export */
const objectIsEmpty = (obj) => Object.keys(obj).length === 0;

export { objectIsEmpty };
export const convertNumberToCurrency = (number, options = {}) => {
    const currency = options.currency ?? 'MXN';
    const location = options.location ?? 'es-MX';
    const maximumSignificantDigits = options.maximumDigits ?? 4;
    const maximumFractionDigits = options.maximumFractionDigits ?? 4;

    return new Intl.NumberFormat(location, { style: 'currency', currency, maximumSignificantDigits, maximumFractionDigits }).format(number);
};

export const getMonthsBetweenRange = (initalDate, endDate) => {
    const dates = eachMonthOfInterval({ start: initalDate, end: endDate });

    return dates.map((date) => format(date, 'yyyy MMM'));
};

class BiMap {
    constructor(obj) {
        this.obj = obj;
        this.reverseObj = {};

        const reverse = Object.keys(obj).reduce((reverseObj, key) => {
            const value = obj[key];
            if (value in reverseObj) {
                throw new Error('BiMap cannot have duplicated values.');
            }

            reverseObj[value] = key;
            return reverseObj;
        }, {});

        this.reverseObj = reverse;
    }

    getKey(value) {
        return this.reverseObj[value];
    }

    getValue(key) {
        return this.obj[key];
    }
}

export const BiMapMonths = new BiMap({
    Jan: 0,
    Feb: 1,
    Mar: 2,
    Apr: 3,
    May: 4,
    Jun: 5,
    Jul: 6,
    Aug: 7,
    Sep: 8,
    Oct: 9,
    Nov: 10,
    Dec: 11
});

export const getMonthByNumber = (monthNumber) => BiMapMonths.getKey(monthNumber - 1);
export const getMonthByName = (monthName) => BiMapMonths.getValue(monthName);

export function downloadFile(file, filename = 'downloaded-file.zip') {
    const link = document.createElement('a');
    link.href = file;
    link.download = filename; // Use the specified filename
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
